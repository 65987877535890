import React from "react"
import { graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import ContactForm from "../ContactForm/ContactForm"
import styles from "./hero.module.scss"

export const fragment = graphql`
  fragment HeroSection on WPGraphQL_Page_Sectionfields_Sections_Hero {
    title
    subtitle
    backgroundimage {
      sourceUrl
      imageFile {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`

const Hero = ({ title, backgroundimage, subtitle }) => (
  <BackgroundImage
    className={styles.HeroSection}
    Tag="section"
    fluid={backgroundimage.imageFile.childImageSharp.fluid}
    backgroundColor={`#040e18`}
  >
    <div id="hero" className="anchor"></div>
    <div className={`container ${styles.Container}`}>
      <div className={`row ${styles.Row}`}>
        <div className={`col-md-12 ${styles.Col}`}>
          <div className={styles.titlesWrapper}>
            <h1>{title}</h1>
            <h5>{subtitle}</h5>
          </div>

          <a className={`btn btn-primary ${styles.GetStarted}`} href="#contact">
            Get Started
          </a>
          <div className={styles.ContactFormWrapper}>
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  </BackgroundImage>
)

export default Hero
