import React from "react"
import { graphql } from "gatsby"
import ToggleText from "../ToggleText"
import styles from "./Services.module.scss"
import BackgroundImage from "gatsby-background-image"

export const fragment = graphql`
  fragment ServicesSection on WPGraphQL_Page_Sectionfields_Sections_Services {
    title
    content
    backgroundImage {
      sourceUrl
    }
    backgroundImage2 {
      sourceUrl
    }
    services {
      ... on WPGraphQL_Page_Sectionfields_Sections_Services_services {
        title
        content
        image {
          sourceUrl
          imageFile {
            childImageSharp {
              fluid(maxWidth: 652) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

const Services = ({ title, content, services }) => (
  <section id="services" className={styles.ServicesSection}>
    <div className="VectorTop" style={{ background: "#ffffff" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 1920 211"
        fill="none"
      >
        <path
          d="M1923 0C1164.2 262.098 322.833 109.207 -3 0V211H1923V0Z"
          fill="#EDEDED"
        />
      </svg>
    </div>

    <div className={`container ${styles.Container}`}>
      <div className="row">
        <div className="col-md-7">
          <h2 className={styles.heading}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="42"
              viewBox="0 0 36 42"
              fill="none"
            >
              <rect
                width="43.2083"
                height="8"
                rx="4"
                transform="matrix(-0.463385 0.886157 0.886157 0.463385 20.5221 0)"
                fill="#26326D"
              />
              <rect
                width="29.0519"
                height="8"
                rx="4"
                transform="matrix(-0.463385 0.886157 0.886157 0.463385 28.4243 12.4692)"
                fill="#26326D"
              />
            </svg>
            {title}
          </h2>
        </div>
        <div className="col-md-5">
        <p className={styles.content}>{content}</p>
        </div>
      </div>

      <div className={`row ${styles.ServicesWrapper}`}>
        {services.map((service, index) => (
          <div key={index} className="col-md-12" className={styles.thatCol}>
            <div className={styles.ServicesWrapper___service}>
              <BackgroundImage
                fluid={service.image.imageFile.childImageSharp.fluid}
                className={styles.ImageSide}
              ></BackgroundImage>

              {/* <Img
                className={styles.ImageSide}
                fluid={service.image.imageFile.childImageSharp.fluid}
              /> */}

              <div className={styles.ContentSide}>
                <ToggleText
                  id={index}
                  toggled={index === 0 ? true : false}
                  title={service.title}
                  content={service.content}
                />
                {/* <h5>{service.title}</h5>
                <p dangerouslySetInnerHTML={{__html: service.content}}></p> */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    <div className="VectorBot" style={{ background: "#ffffff" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 1920 224"
        fill="none"
      >
        <path
          d="M-3 224C755.8 -38.0979 1597.17 114.793 1923 224L1923 0L-2.99986 -5.29588e-07L-3 224Z"
          fill="#EDEDED"
        />
      </svg>
    </div>
  </section>
)

export default Services
