import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"

import Hero from "../components/Hero/Hero"
import Values from "../components/Values/Values"
import About from "../components/AboutUs/About"
import Services from "../components/Services/Services"
import Features from "../components/Features/Features"
import Contact from "../components/Contact/Contact"
import { Helmet } from "react-helmet"

export const query = graphql`
  {
    wpgraphql {
      pageBy(uri: "home") {
        id
        title
        sectionFields {
          sections {
            __typename
            ...HeroSection
            ...AboutSection
            ...ValuesSection
            ...ServicesSection
            ...FeaturesSection
            ...ContactSection
          }
        }
      }
    }
  }
`

const Home = ({ data, location }) => {
  const sections = data.wpgraphql.pageBy.sectionFields.sections
  return (
    <Layout location={location}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Adriatic Corporate Solutions</title>
      </Helmet>
      {sections.map((section, index) => {
        const typeName = section.__typename

        switch (typeName) {
          case "WPGraphQL_Page_Sectionfields_Sections_Hero":
            return <Hero key={index} {...section} />

          case "WPGraphQL_Page_Sectionfields_Sections_Aboutus":
            return <About key={index} {...section} />

          case "WPGraphQL_Page_Sectionfields_Sections_Valuessection":
            return <Values key={index} {...section} />

          case "WPGraphQL_Page_Sectionfields_Sections_Services":
            return <Services key={index} {...section} />

          case "WPGraphQL_Page_Sectionfields_Sections_Features":
            return <Features key={index} {...section} />

          case "WPGraphQL_Page_Sectionfields_Sections_Contact":
            return <Contact key={index} {...section} />

          default:
            return <p>You done busted it.</p>
        }
      })}
    </Layout>
  )
}

export default Home
