import React from "react"

class ToggleText extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      readMore: this.props.toggled,
    }
  }

  handleReadMoreClick() {
    this.setState({ readMore: !this.state.readMore })
  }

  render() {
    return (
      <span>
        <h5>{this.props.title}</h5>
        <p className={this.state.readMore ? "read-more" : "read-less"}>
          {this.props.content}
        </p>
        <p
          onClick={() => {
            this.handleReadMoreClick(this.props.id)
          }}
        >
          <b> {this.state.readMore ? "" : "read more"}</b>
        </p>
      </span>
    )
  }
}

export default ToggleText
