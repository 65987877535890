import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import styles from "./Contact.module.scss"
import ContactForm from "../ContactForm/ContactForm"

export const fragment = graphql`
  fragment ContactSection on WPGraphQL_Page_Sectionfields_Sections_Contact {
    title
    content
  }
`

const Contact = ({ title, content }) => (
  <section className={styles.ContactSection}>
    <div id="contact" className="anchor"></div>

    <div className={`container ${styles.contactContainer}`}>
      <div className={`row ${styles.contactRow}`}>
        <div className="col-md-6">
          <div className={styles.contactSideWrapper}>
            <h2 className={styles.heading}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="42"
                viewBox="0 0 36 42"
                fill="none"
              >
                <rect
                  width="43.2083"
                  height="8"
                  rx="4"
                  transform="matrix(-0.463385 0.886157 0.886157 0.463385 20.5221 0)"
                  fill="#26326D"
                />
                <rect
                  width="29.0519"
                  height="8"
                  rx="4"
                  transform="matrix(-0.463385 0.886157 0.886157 0.463385 28.4243 12.4692)"
                  fill="#26326D"
                />
              </svg>
              {title}
            </h2>

            <p>{content}</p>

            <ContactForm className="BottomForm" />
          </div>
        </div>
        <div className="col-md-6">
          <div className={styles.googleMaps}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1776.1533626543614!2d19.243324430032615!3d42.442713588153566!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xde926b1166f60549!2sSchoenherr%20Montengero%20-%20Moravcevic%20Vojnovic%20i%20Partneri%20in%20cooperation%20with%20Schoenherr!5e0!3m2!1sen!2sua!4v1595235555078!5m2!1sen!2sua"
              width="600"
              height="450"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            ></iframe>

            {/* <GoogleMap /> */}
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Contact
