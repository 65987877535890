import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import styles from "./About.module.scss"

export const fragment = graphql`
  fragment AboutSection on WPGraphQL_Page_Sectionfields_Sections_Aboutus {
    tiltle
    text
    image {
      sourceUrl
      imageFile {
        childImageSharp {
          fluid(maxWidth: 535) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`

const About = ({ tiltle, image, text }) => (
  <section className={styles.Section}>
    <div id="about" className="anchor"></div>

    <div className="container">
      <div className="row">
        <div className={`col-md-6 ${styles.LeftSide}`}>
          <Img fluid={image.imageFile.childImageSharp.fluid} />
        </div>
        <div className={`col-md-6 ${styles.RightSide}`}>
          <h2 className={styles.heading}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="42"
              viewBox="0 0 36 42"
              fill="none"
            >
              <rect
                width="43.2083"
                height="8"
                rx="4"
                transform="matrix(-0.463385 0.886157 0.886157 0.463385 20.5221 0)"
                fill="#26326D"
              />
              <rect
                width="29.0519"
                height="8"
                rx="4"
                transform="matrix(-0.463385 0.886157 0.886157 0.463385 28.4243 12.4692)"
                fill="#26326D"
              />
            </svg>
            {tiltle}
          </h2>
          <p>{text}</p>
        </div>
      </div>

    </div>
  </section>
)

export default About
