import React, { Component } from "react"
import { Form, Button } from "react-bootstrap"
import querystring from "query-string"
import axios from "axios"
import FormErrors from "./FormErrors"

import { wpUrl } from "../../../globals"

import styles from "./ContactForm.module.scss"




const initialState = {
  firstName: "",
  email: "",
  option: "",
  formErrors: { firstName: "", email: "", option: "" },
  firstNameValid: false,
  emailValid: false,
  optionValid: false,
  formValid: false,
  emailSent: false
}

class ContactForm extends Component {
  

  state = initialState

  reset() {
    // reseting state
      this.setState(initialState);

      // update inputs
      this.refs.firstName.value = '';
      this.refs.email.value = '';
      this.refs.option.value = 'DEFAULT';

  }

  handleChange = input => event => {
    const value = event.target.value

    this.setState({ [input]: value }, () => {
      this.validateField(input, value)
    })
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors

    let emailValid = this.state.emailValid
    let firstNameValid = this.state.firstNameValid
    let optionValid = this.state.optionValid

    switch (fieldName) {
      case "email":
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        fieldValidationErrors.email = emailValid ? "" : "Email is invalid"
        break

      case "option":
        optionValid = value.length >= 1
        fieldValidationErrors.option = optionValid ? "" : "Select an option"

        console.log(value)
        break

      case "firstName":
        firstNameValid = value.length >= 1
        fieldValidationErrors.firstName = firstNameValid
          ? ""
          : "Name is required"
        break

      default:
        break
    }

    this.setState(
      {
        formErrors: fieldValidationErrors,
        emailValid: emailValid,
        firstNameValid: firstNameValid,
        optionValid: optionValid,
      },
      this.validateForm
    )
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.emailValid &&
        this.state.firstNameValid &&
        this.state.optionValid,
    })
  }

  handleSubmit = event => {
    event.preventDefault()

    if (this.state.formValid) {
      console.log("form valid")

      const data = {
        name: this.state.firstName,
        email: this.state.email,
        option: this.state.option,
      }
      this.reset()
      ///process
      axios.post(`${wpUrl}/mail.php`, querystring.stringify(data)).then(res => {
        this.setState({emailSent: true})

        console.log(res)
        // alert("Thank you! Email has been sent")
      })
    } else {
      console.log("form invalid")

      this.validateField("firstName", this.state.firstName)
      this.validateField("email", this.state.email)
      this.validateField("option", this.state.option)
    }
  }

  render() {
    return (
      <div>
        <Form className={styles.form}>
          <Form.Group className={styles.Form___formGroup}>
            <Form.Control
              className={styles.Form___formControl}
              onChange={this.handleChange("option")}
              defaultValue={"DEFAULT"}
              as="select"
              ref="option"
            >
              <option disabled value="DEFAULT">
                Choose service
              </option>
              <option>EU non Tax company with Bank account</option>
              <option>Nominal services</option>
              <option>Bank account</option>
              <option>EU non Tax company formation</option>
            </Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Control
              className={styles.Form___formControl}
              onChange={this.handleChange("firstName")}
              type="text"
              placeholder="Name"
              ref="firstName"
            />
          </Form.Group>

          <Form.Group>
            <Form.Control
              className={styles.Form___formControl}
              onChange={this.handleChange("email")}
              type="email"
              placeholder="Email"
              ref="email"
            />
          </Form.Group>
          <Button
            className={`${this.state.formValid ? `${styles.ButtonValid}` : ''} ${styles.Form___formSubmit}`}
            onClick={this.handleSubmit}
            type="submit"
            // disabled={!this.state.formValid}
          >
            SEND
          </Button>
        </Form>
        <div className={`${styles.panel} ${styles.panelDefault}`}>
          <FormErrors formErrors={this.state.formErrors} />
          <div className={`${this.state.emailSent ? `${styles.openSuccess}` : ''}  ${styles.Success} ${styles.panel}`} >Thank you! Email has been sent</div>
        </div>
      </div>
    )
  }
}

export default ContactForm
