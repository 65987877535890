import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import styles from "./Features.module.scss"

export const fragment = graphql`
  fragment FeaturesSection on WPGraphQL_Page_Sectionfields_Sections_Features {
    title
    content
    list {
      listItem
    }
    image {
      sourceUrl
      imageFile {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`

const Features = ({ title, image, content, list }) => (
  <section className={styles.FeaturesSection}>
    <div id="features" className="anchor"></div>

    <div className={`container`}>
      <div className="row">
        <div className="col-md-6">
          <div className={styles.gimgWrapper}>
            <Img fluid={image.imageFile.childImageSharp.fluid} />
          </div>
        </div>
        <div className="col-md-6">
          <div className={styles.featuresWrapper}>
            <h2 className={styles.heading}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="42"
                viewBox="0 0 36 42"
                fill="none"
              >
                <rect
                  width="43.2083"
                  height="8"
                  rx="4"
                  transform="matrix(-0.463385 0.886157 0.886157 0.463385 20.5221 0)"
                  fill="#26326D"
                />
                <rect
                  width="29.0519"
                  height="8"
                  rx="4"
                  transform="matrix(-0.463385 0.886157 0.886157 0.463385 28.4243 12.4692)"
                  fill="#26326D"
                />
              </svg>
              {title}
            </h2>

            <p>{content}</p>
            <ul>
              {list.map(({ listItem }, index) => (
                <li key={index}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                  >
                    <path
                      d="M10.4112 19.2918C9.36839 20.3346 7.6764 20.3346 6.63412 19.2918L0.782084 13.4397C-0.260695 12.3974 -0.260695 10.7053 0.782084 9.66302C1.82436 8.62022 3.51636 8.62022 4.55913 9.66302L8.0458 13.1493C8.30901 13.412 8.73629 13.412 9 13.1493L18.4409 3.70819C19.4831 2.66539 21.1751 2.66539 22.2179 3.70819C22.7187 4.20895 23 4.88838 23 5.5965C23 6.30462 22.7187 6.98405 22.2179 7.48482L10.4112 19.2918Z"
                      fill="#1CC600"
                    />
                  </svg>
                  {listItem}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Features
