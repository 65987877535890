import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import styles from "./Values.module.scss"

export const fragment = graphql`
  fragment ValuesSection on WPGraphQL_Page_Sectionfields_Sections_Valuessection {
    title
    image {
      sourceUrl
      imageFile {
        childImageSharp {
          fluid(maxWidth: 535) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    content
    values {
      ... on WPGraphQL_Page_Sectionfields_Sections_Valuessection_values {
        title
        content
        icon {
          sourceUrl
          imageFile {
            childImageSharp {
              fixed(width: 120) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`

const Values = ({ title, image, content, values }) => (
  <section className={styles.ValuesSection}>
    <div id="values" className="anchor"></div>

    <div className="container">
      <div className="row">
        <div className={`col-md-6 ${styles.LeftSide}`}>
          <h2 className={styles.heading}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="42"
              viewBox="0 0 36 42"
              fill="none"
            >
              <rect
                width="43.2083"
                height="8"
                rx="4"
                transform="matrix(-0.463385 0.886157 0.886157 0.463385 20.5221 0)"
                fill="#26326D"
              />
              <rect
                width="29.0519"
                height="8"
                rx="4"
                transform="matrix(-0.463385 0.886157 0.886157 0.463385 28.4243 12.4692)"
                fill="#26326D"
              />
            </svg>
            {title}
          </h2>
          <p>{content}</p>
        </div>
        <div className={`col-md-6 ${styles.RightSide}`}>
          <Img fluid={image.imageFile.childImageSharp.fluid} />
        </div>
      </div>
      <div className={`row ${styles.ValuesWrapper}`}>
          {values.map((value, index) => (
            <div key={index} className="col-md-4">
              <Img className={styles.ValuesWrapper___img} fixed={value.icon.imageFile.childImageSharp.fixed} />
              <h5>{value.title}</h5>
              <p>{value.content}</p>
            </div>
          ))}
        </div>
    </div>
  </section>
)

export default Values
